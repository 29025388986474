<template>
  <div v-if="types.objects.length > 0">
    <searchedContents :contents="types" :localLang="localLang"></searchedContents>
  </div>
</template>

<script>
export default {
  props: {
    types: {
      type: Object
    },
    localLang: {
      type: String
    }
  },
  data() {
    return {};
  },
  created() {
    console.log("types in search slider", this.types);
  },
  components: {
    searchedContents: () =>
      import(
        /* webpackChunkName: "searchedContents" */ "./SearchedContentsCarousel.vue"
      )
  }
};
</script>

<style lang="scss" scoped>
</style>